/* AboutUs.css */

.logo-fade-in {
    opacity: 5;
    transition: slideIn 1s ease-in-out;
}

.slogan-slide-in {
    opacity: 0;
    animation: slideIn 1s ease-in-out forwards;
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.scroll-container {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    height: 100vh;
   
}

.section {
    scroll-snap-align: start;
    height: 100%;
    
}
.glitch-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 50px;
    /* align-items: center; */
    /* justify-content: center; */
    /* text-align: center; */
   
 }
 /* Initial state for the entering element */

 .glitch {
    position: relative;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 4px;
    z-index: 1;
 }
 
 .glitch:before {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    color: #fff;
    /* background-color: #222; */
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-before 3s infinite linear alternate-reverse;
 }
 
 .glitch:after {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    color: #fff;
    /* background-color: #222; */
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-after 1s infinite linear alternate-reverse;
 }
 
 @keyframes noise-before {
    0% {
       clip: rect(61px, 9999px, 52px, 0);
    }
 
    5% {
       clip: rect(33px, 9999px, 144px, 0);
    }
 
    10% {
       clip: rect(121px, 9999px, 115px, 0);
    }
 
    15% {
       clip: rect(144px, 9999px, 162px, 0);
    }
 
    20% {
       clip: rect(62px, 9999px, 180px, 0);
    }
 
    25% {
       clip: rect(34px, 9999px, 42px, 0);
    }
 
    30% {
       clip: rect(147px, 9999px, 179px, 0);
    }
 
    35% {
       clip: rect(99px, 9999px, 63px, 0);
    }
 
    40% {
       clip: rect(188px, 9999px, 122px, 0);
    }
 
    45% {
       clip: rect(154px, 9999px, 14px, 0);
    }
 
    50% {
       clip: rect(63px, 9999px, 37px, 0);
    }
 
    55% {
       clip: rect(161px, 9999px, 147px, 0);
    }
 
    60% {
       clip: rect(109px, 9999px, 175px, 0);
    }
 
    65% {
       clip: rect(157px, 9999px, 88px, 0);
    }
 
    70% {
       clip: rect(173px, 9999px, 131px, 0);
    }
 
    75% {
       clip: rect(62px, 9999px, 70px, 0);
    }
 
    80% {
       clip: rect(24px, 9999px, 153px, 0);
    }
 
    85% {
       clip: rect(138px, 9999px, 40px, 0);
    }
 
    90% {
       clip: rect(79px, 9999px, 136px, 0);
    }
 
    95% {
       clip: rect(25px, 9999px, 34px, 0);
    }
 
    100% {
       clip: rect(173px, 9999px, 166px, 0);
    }
 }
 
 @keyframes noise-after {
    0% {
       clip: rect(26px, 9999px, 33px, 0);
    }
 
    5% {
       clip: rect(140px, 9999px, 198px, 0);
    }
 
    10% {
       clip: rect(184px, 9999px, 89px, 0);
    }
 
    15% {
       clip: rect(121px, 9999px, 6px, 0);
    }
 
    20% {
       clip: rect(181px, 9999px, 99px, 0);
    }
 
    25% {
       clip: rect(154px, 9999px, 133px, 0);
    }
 
    30% {
       clip: rect(134px, 9999px, 169px, 0);
    }
 
    35% {
       clip: rect(26px, 9999px, 187px, 0);
    }
 
    40% {
       clip: rect(147px, 9999px, 137px, 0);
    }
 
    45% {
       clip: rect(31px, 9999px, 52px, 0);
    }
 
    50% {
       clip: rect(191px, 9999px, 109px, 0);
    }
 
    55% {
       clip: rect(74px, 9999px, 54px, 0);
    }
 
    60% {
       clip: rect(145px, 9999px, 75px, 0);
    }
 
    65% {
       clip: rect(153px, 9999px, 198px, 0);
    }
 
    70% {
       clip: rect(99px, 9999px, 136px, 0);
    }
 
    75% {
       clip: rect(118px, 9999px, 192px, 0);
    }
 
    80% {
       clip: rect(1px, 9999px, 83px, 0);
    }
 
    85% {
       clip: rect(145px, 9999px, 98px, 0);
    }
 
    90% {
       clip: rect(121px, 9999px, 154px, 0);
    }
 
    95% {
       clip: rect(156px, 9999px, 44px, 0);
    }
 
    100% {
       clip: rect(67px, 9999px, 122px, 0);
    }
 }
 
 /* Keyframes for the flying animation */
@keyframes fly-up-down {
    0%, 100% {
      transform: translateY(0); /* Start and end position (no vertical movement) */
    }
    50% {
      transform: translateY(-20px); /* Move up halfway through the animation */
    }
  }
  
  /* Apply the flying animation to the .flying-img element */
  .flying-img {
    animation: fly-up-down 3s ease-in-out infinite; /* Adjust the duration as needed */
  }
/* Keyframes for the popping animation */
/* Keyframes for the popping animation */
@keyframes pop-left {
    0%, 100% {
      transform: translateY(100%) scale(1); /* Start and end position (off the left side of the screen) with no scaling */
    }
    20%, 40%, 60%, 80% {
      transform: translateY(0) scale(1.2); /* Pops and zooms in */
    }
  }
  
  /* Apply the popping animation to the .popping-img element */
  .popping-img {
    animation: pop-left 2s linear infinite; /* Adjust the duration as needed */
  }
  .glitch-wrapper-solplay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #222;
 }
 
 .glitch-solplay {
    position: relative;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
 }
 
 .glitch-solplay:before,
 .glitch-solplay:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
 }
 
 .glitch-solplay:before {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #0ff;
    z-index: -1;
 }
 
 .glitch-solplay:after {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #ff00ff;
    z-index: -2;
 }
 
 @keyframes glitch-color {
    0% {
       transform: translate(0);
    }
 
    20% {
       transform: translate(-3px, 3px);
    }
 
    40% {
       transform: translate(-3px, -3px);
    }
 
    60% {
       transform: translate(3px, 3px);
    }
 
    80% {
       transform: translate(3px, -3px);
    }
 
    to {
       transform: translate(0);
    }
 }
 /* Add a class to style the scroll button */
.scroll-button {
   position: absolute;
   bottom: 20px; /* Adjust the positioning as needed */
   left: 50%;
   transform: translateX(-50%);
   transition: transform 0.3s; /* Add a smooth transition for the transform property */
 }
 
 /* Style the anchor element within the scroll button */
 .scroll-button a {
   text-decoration: none;
   color: #fff;
   font-size: 24px;
 }
 
 /* Define the hover effect to make it move slightly on hover */
 .scroll-button:hover {
   transform: translateX(-50%) translateY(-5px); /* Adjust translateY for the desired effect */
 }
 